<template>
    <div class="v-showcase-entry entry-view" :class="{'archive-is-active': archiveIsActive, 'has-archive': displayArchive }">
        <div ref="scrollContainer" @scroll="onScroll" class="v-showcase-entry-scroll-container">
            <div ref="showcaseViewContent" class="view-content">
                <showcase-category-navigation :current-category="categorySlug" />
                <default-entry-content :entry="entry"/>
            </div>

            <header ref="archiveHeader" class="section-header container-fluid showcase-archive-header" v-if="displayArchive">
                <h1 class="section-title"><span @click="onArchiveHeaderClicked()">Archiv</span></h1>
            </header>
            <div ref="archiveView" class="showcase-archive-content" v-if="displayArchive">
                <archive-index :filterQuery="archiveFilterQuery" :key="archiveOverviewComponentKey" :preselected-filters="preselectedFilters" :componentKey="archiveOverviewComponentKey" is-showcase-archive="true" />
            </div>
        </div>
    </div>
</template>

<script>
import ShowcaseCategoryNavigation from '@/components/ShowcaseCategoryNavigation.vue';
import DefaultEntryContent from '@/components/DefaultEntryContent.vue';
import ArchiveIndex from '@/views/ArchiveIndex.vue';
import { fetchArchiveCategories } from '../http/fetchApi';

import { fetchShowcaseEntryBySlug } from '../http/fetchApi';

export default {
    name: 'ShowcaseEntry',
    components: {
        ShowcaseCategoryNavigation,
        DefaultEntryContent,
        ArchiveIndex
    },
    props: {
        slug: String,
        categorySlug: String
    },
    data() {
        return {
            entry:{},
            archiveFilterQuery: {},
            archiveIsActive: false,
            preselectedFilters: {},
            sectionHeaderHeight: document.getElementsByClassName("section-header")[0].clientHeight,
            resizeT: false,
            archiveOverviewComponentKey: null,
            routeLeftToArchiveEntry: false,
            routeLeftToArchiveIndex: false,
            storedScrollPositionOnRouteLeave:0,
            displayArchive: false,
        }
    },
    mounted() {
        this.fetchEntry();
        window.addEventListener('resize', this.onWindowResize);
    },
    updated() {
        this.$nextTick(function () {
            if (this.routeLeftToArchiveEntry) {
                this.$refs.scrollContainer.scrollTop = this.storedScrollPositionOnRouteLeave;
                this.routeLeftToArchiveEntry = false;
                this.storedScrollPositionOnRouteLeave = 0;
            } else if (this.routeLeftToArchiveIndex) { // fix some issues when navigating forward and backwards to archive
                this.routeLeftToArchiveIndex = false;
                this.$refs.scrollContainer.scrollTop = 0;
                //force rerender of archive component
                this.setArchiveOverviewComponentKey();
            }
        });
    },
    unmounted() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    beforeRouteLeave (to, from) {
        if (to.name == 'ArchiveEntry') {
            this.routeLeftToArchiveEntry = true;
            this.storedScrollPositionOnRouteLeave = this.$refs.scrollContainer.scrollTop;
        } else if (to.name == 'ArchiveIndex' && this.displayArchive) {
            this.routeLeftToArchiveIndex = true;
        }
    },
    methods: {
        async fetchEntry() {
            const { data } = await fetchShowcaseEntryBySlug(this.slug);
            this.entry = data[0];

            if (this.entry.atomId || this.entry.preselectedArchiveFilters) {

                // fetch Archive Categories to map filters
                const { data } = await fetchArchiveCategories();
                this.$globalData.archiveCategories[this.$i18n.locale] = data;

                if (this.entry.atomId) {
                    this.createArchiveQueryByAtomId(this.entry.atomId);
                }
                if (this.entry.preselectedArchiveFilters) {
                    this.groupPreselectedFilters(this.entry.preselectedArchiveFilters);
                }
                if (this.displayArchive) {
                    this.setArchiveOverviewComponentKey();
                }
            }
            // set header data
            const contentParagraphs = this.entry.content.match(/<p>([\s\S]*)?<\/p>/i)||[];
            this.$globalData.setHeaderData({
                title: 'F+F 1971 - '+this.entry.title,
                description: contentParagraphs[1] || ''
            });
        },
        onScroll(e) {
            if (e.srcElement.scrollTop > this.$refs.showcaseViewContent.clientHeight-1) {
                this.archiveIsActive = true;
            } else {
                this.archiveIsActive = false;
            }
        },
        onArchiveHeaderClicked() {
            this.scrollToArchive();
        },
        scrollToArchive() {
            const offsetTop = this.$refs.showcaseViewContent.clientHeight;

            this.$refs.scrollContainer.scrollTo({
                top: offsetTop,
                behavior: "smooth"
            });
        },
        onWindowResize() {
            if (this.resizeT){
                clearTimeout(this.resizeT);
            }
            const that = this;
            this.resizeT = setTimeout(function() {
                that.sectionHeaderHeight =  document.getElementsByClassName("section-header")[0].clientHeight;
                that.$el.dispatchEvent(new Event('scroll'));
            }, 200);
        },
        setArchiveOverviewComponentKey() {
            this.archiveOverviewComponentKey = 'showcaseArchiveOverview-'+ Math.floor(Math.random() * 10000000);
        },
        createArchiveQueryByAtomId(filterId) {
            for (const groupKey in this.$globalData.archiveCategories[this.$i18n.locale]) {
                const filterObj = this.$globalData.archiveCategories[this.$i18n.locale][groupKey].find(filter => filter.id == filterId);
                if (typeof filterObj !== 'undefined') {
                    this.archiveFilterQuery[groupKey] = filterObj.name;
                    this.displayArchive = true;
                    break;
                }
            }
        },
        groupPreselectedFilters(filterIdArr) {
            for (const groupKey in this.$globalData.archiveCategories[this.$i18n.locale]) {
                let selectedGroupFilters = [];
                const filterGroup = this.$globalData.archiveCategories[this.$i18n.locale][groupKey];
                for (var selectedFilter_i = 0; selectedFilter_i < filterIdArr.length; selectedFilter_i++) {
                    const filterId = filterIdArr[selectedFilter_i];
                    const filterObj = filterGroup.find(filter => filter.id == filterId);
                    if (typeof filterObj !== 'undefined') {
                        selectedGroupFilters.push(filterObj.name);
                    }
                }
                if (selectedGroupFilters.length) {
                    this.preselectedFilters[groupKey] = selectedGroupFilters;
                    this.displayArchive = true;
                }
            }
        },
    }
}
</script>
